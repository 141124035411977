<template>
  <div class="calculation">
    <div class="main" v-bind:class="activeClass">
      <h1>Spannungsabfall | Drehstrom</h1>

            <table>
              <tr class="formula">
                <td rowspan="2"><span class="value_a">A</span> =</td>
                <td class="line">&radic;3 * <span class="value_l">l</span> * <span class="value_i">I</span> * <span class="value_cos">cos φ</span></td>
              </tr>
              <tr class="formula">
                <td rowspan="2"><span class="value_γ">&gamma;</span> * <span class="value_Ua">Ua</span></td>
              </tr>
            </table>

      <form @submit.prevent="calculate()" class="calculation">
        <div @click="toggleActiveRow('value_a')" class="calculation__row value_a">
          <span class="error" v-if="value_a_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_a_error}" for="a">A</label>
          <input :class="{'error' : value_a_error}" v-model="value_a" type="text" name="a" placeholder="Querschnitt in mm&sup2;">
        </div>
        <div @click="toggleActiveRow('value_l')" class="calculation__row value_l">
          <span class="error" v-if="value_l_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_l_error}" for="l">l</label>
          <input :class="{'error' : value_l_error}" v-model="value_l" type="text" name="l" placeholder="Einfache Kabellänge in Meter">
        </div>
        <div @click="toggleActiveRow('value_i')" class="calculation__row value_i">
          <span class="error" v-if="value_i_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_i_error}" for="i">I</label>
          <input :class="{'error' : value_i_error}" v-model="value_i" type="text" name="i" placeholder="Strom in Ampere">
        </div>
         <div @click="toggleActiveRow('value_cos')" class="calculation__row value_cos">
          <span class="error" v-if="value_cos_error">Der Wirkleistungfaktor muss zwischen 0 und 1 sein!</span>
          <label :class="{'error' : value_cos_error}" for="cos">cos φ</label>
          <input :class="{'error' : value_cos_error}" v-model="value_cos" type="text" name="cos" placeholder="Wirkleistungfaktor">
        </div>
        <div @click="toggleActiveRow('value_γ')" class="calculation__row value_γ">
          <span class="error" v-if="value_γ_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_γ_error}" for="γ">γ</label>
          <input :class="{'error' : value_γ_error}" v-model="value_γ" type="text" name="γ" placeholder="Elektrische Leitfähigkeit">
        </div>
        <div @click="toggleActiveRow('value_Ua')" class="calculation__row value_Ua">
          <span class="error" v-if="value_Ua_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_Ua_error}" for="Ua">Ua</label>
          <input :class="{'error' : value_Ua_error}" v-model="value_Ua" type="text" name="Ua" placeholder="Spannungsfall in Volt">
        </div>
        <div class="calculation__row">
          <div class="submission__row">
            <button type="submit">Jetzt berechnen</button>
            <table class="forms" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <td>Formel-Elemente</td>
                </tr>
              </thead>
              <tbody>
                <tr class="value_a">
                  <td><b>A:</b> Querschnitt (mm&sup2;)</td>
                </tr>
                <tr class="value_l">
                  <td><b>l:</b> Einfache Kabellänge (m)</td>
                </tr>
                <tr class="value_i">
                  <td><b>I:</b> Strom (A)</td>
                </tr>
                <tr class="value_cos">
                  <td>
                    <table>
                      <tr>
                        <td colspan="3">
                          <b>cos φ</b>
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2">Wirkleistungfaktor = </td>
                        <td class="line">P</td>
                        <td rowspan="2">= cos φ</td>
                      </tr>
                      <tr>
                        <td>S</td>
                      </tr>
                    </table>
                    <span><b>P:</b> Wirkleistung</span>
                    <span><b>S:</b> Scheinleistung</span>
                  </td>
                </tr>
                <tr class="value_γ">
                  <td>
                  <span><b>γ:</b> Elektrische Leitfähigkeit</span>&nbsp;
                    <tr>
                    <td><b>Kupfer:&nbsp;</b></td>
                    <td>56 m/Ωmm²</td>
                    </tr>
                    <tr>
                    <td><b>Aluminium:&nbsp;</b></td>
                    <td>36 m/Ωmm²</td>
                    </tr>
                  </td>
                </tr>
                <tr class="value_Ua">
                  <td><b>Ua:</b> Spannungsfall (V)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeClass: '',
      value_a: null,
      value_a_error: false,
      value_l: null,
      value_l_error: false,
      value_i: null,
      value_i_error: false,
      value_cos: null,
      value_cos_error: false,
      value_γ: null,
      value_γ_error: false,
      value_Ua: null,
      value_Ua_error: false,
    }
  },
  methods: {
    toggleActiveRow(data, result = false) {
      if(result){
        this.activeClass = data + ' result';
      }else{
        this.activeClass = data;
      }

},
      calculate(){
      this.value_a_error = false;
      this.value_l_error = false;
      this.value_i_error = false;
      this.value_cos_error = false;
      this.value_γ_error = false;
      this.value_Ua_error = false;

      if(this.value_a == 0){
        this.value_a_error = true;
        return;
      }

      if(this.value_l == 0){
        this.value_l_error = true;
        return;
      }

      if(this.value_i == 0){
        this.value_i_error = true;
        return;
      }

      if(this.value_cos == 0 || this.value_cos < 0 || this.value_cos > 1){
        this.value_cos_error = true;
        return;
      }

      if(this.value_γ == 0){
        this.value_γ_error = true;
        return;
      }

      if(this.value_Ua == 0){
        this.value_Ua_error = true;
        return;
      }

      //Error wenn kein Input befüllt ist
      if(!this.value_a && !this.value_l && !this.value_i && !this.value_cos && !this.value_γ && !this.value_Ua){
          this.value_a_error = true;
          this.value_l_error = true;
          this.value_i_error = true;
          this.value_cos_error = true;
          this.value_γ_error = true;
          this.value_Ua_error = true;

      //Berechnung von A = (√3*l*i*cos)/(γ*Ua)
      }else if(this.value_l && this.value_i && this.value_cos && this.value_γ && this.value_Ua){
        this.value_a = Math.round((Math.sqrt(3) * this.value_l * this.value_i * this.value_cos) / (this.value_γ * this.value_Ua) * 100) / 100;
        this.toggleActiveRow('value_a', true);

      //Berechnung von L = (A*γ*Ua)/(√3*i*cos)
      }else if(this.value_a && this.value_γ && this.value_Ua && this.value_i && this.value_cos){
        this.value_l = Math.round((this.value_a * this.value_γ * this.value_Ua) / (Math.sqrt(3) * this.value_i * this.value_cos) * 100) / 100;
        this.toggleActiveRow('value_l', true);

      //Berchnung von I = (A*γ*Ua)/(√3*l*cos)
      }else if(this.value_a && this.value_γ && this.value_Ua && this.value_l && this.value_cos){
        this.value_i = Math.round((this.value_a * this.value_γ * this.value_Ua) / (Math.sqrt(3) * this.value_l * this.value_cos) * 100) / 100;
        this.toggleActiveRow('value_i', true);

      //Berchnung von cos = (A*γ*Ua)/(√3*l*i)
      }else if(this.value_a && this.value_γ && this.value_Ua && this.value_l && this.value_i){
        this.value_cos = Math.round((this.value_a * this.value_γ * this.value_Ua) / (Math.sqrt(3) * this.value_l * this.value_i) * 100) / 100;
        this.toggleActiveRow('value_cos', true);

      //Berechnung von γ = (√3*l*i*cos)/(A*Ua)
      }else if(this.value_l && this.value_i && this.value_cos && this.value_a && this.value_Ua){
        this.value_γ = Math.round((Math.sqrt(3) * this.value_l * this.value_i * this.value_cos) / (this.value_a * this.value_Ua) * 100) / 100;
        this.toggleActiveRow('value_γ', true);

      //Berechnung von Ua = (√3*l*i*cos)/(γ*A)
      }else if(this.value_l && this.value_i && this.value_cos && this.value_γ && this.value_a){
        this.value_Ua = Math.round((Math.sqrt(3) * this.value_l * this.value_i * this.value_cos) / (this.value_γ * this.value_a) * 100) / 100;
        this.toggleActiveRow('value_Ua', true);

      }else{
        if(!this.value_a && !this.value_l ||
        !this.value_a && !this.value_i ||
        !this.value_a && !this.value_γ ||
        !this.value_a && !this.value_Ua ||
        !this.value_a && !this.value_cos ||
        !this.value_l && !this.value_i  ||
        !this.value_l && !this.value_γ ||
        !this.value_l && !this.value_Ua ||
        !this.value_l && !this.value_cos ||
        !this.value_i && !this.value_γ ||
        !this.value_i && !this.value_Ua ||
        !this.value_i && !this.value_cos ||
        !this.value_cos && !this.value_γ ||
        !this.value_cos && !this.value_Ua ||
        !this.value_γ && !this.value_Ua) {
          if(!this.value_a) {
            this.value_a_error = true;
          }
          if(!this.value_l) {
            this.value_l_error = true;
          }
          if(!this.value_i) {
            this.value_i_error = true;
          }
          if(!this.value_cos) {
            this.value_cos_error = true;
          }
          if(!this.value_γ) {
            this.value_γ_error = true;
          }
          if(!this.value_Ua) {
            this.value_Ua_error = true;
          }
        }
      }
    }
  },
  watch: {
    value_a() {
      if(this.value_a == "") {
        this.value_a = null;
      }else if(this.value_a != null && typeof this.value_a == 'string'){
        this.value_a = this.value_a.replace(',', '.');
      }
    },
    value_l() {
      if(this.value_l == "") {
        this.value_l = null;
      }else if(this.value_l != null && typeof this.value_l == 'string'){
        this.value_l = this.value_l.replace(',', '.');
      }
    },
    value_i() {
      if(this.value_i == "") {
        this.value_i = null;
      }else if(this.value_i != null && typeof this.value_i == 'string'){
        this.value_i = this.value_i.replace(',', '.');
      }
    },
    value_cos() {
      if(this.value_cos == "") {
        this.value_cos = null;
      }else if(this.value_cos != null && typeof this.value_cos == 'string'){
        this.value_cos = this.value_cos.replace(',', '.');
      }
    },
    value_γ() {
      if(this.value_γ == "") {
        this.value_γ = null;
      }else if(this.value_γ != null && typeof this.value_γ == 'string'){
        this.value_γ = this.value_γ.replace(',', '.');
      }
    },
    value_Ua() {
      if(this.value_Ua == "") {
        this.value_Ua = null;
      }else if(this.value_Ua != null && typeof this.value_Ua == 'string'){
        this.value_Ua = this.value_Ua.replace(',', '.');
      }
    },
  }
}
</script>