<template>
  <div class="overview">
    <div class="main">
      <h1>Spannungsabfall</h1>
      <p>Soll ein Verbraucher in ein Energienetz eingefügt werden, benötigt dieser eine Stromzufuhr über Kabel von der Spannungsquelle. Unterwegs verliert diese Spannung jedoch an Energie, da alle Spannungsleiter einen Widerstand im Stromfluss darstellen. Dieser Energieverlust ist der Spannungsfall, früher auch Spannungsabfall genannt.</p>
      <p>Genauer erklärt: Der Spannungsverlust zwischen dem Anfang einer Leitung und deren Ende wird als Spannungsfall bezeichnet. Es ist somit die Differenz der Spannungsquelle und des Verbrauchers. Diese Energieverluste entstehen, da der Leiter selbst einen gewissen ohmschen und induktiven Widerstand hat. Dieser Widerstand hat zur Folge, dass Energie in Form von Reibungswärme bzw. Blindleistung verloren geht, weil der Strom nicht ungehindert durch das Kabel fließen kann. Somit fällt proportional dazu eine Spannung ab, die dem Verbraucher nicht mehr zur Verfügung steht. Aus diesem Grund wird häufig noch der „ältere“ Begriff Spannungsabfall verwendet.</p>
      <p>Kabellänge, Kabelquerschnitt und Kabelanzahl pro Phase müssen so berechnet und dimensioniert werden, dass am Ende der Verbindung ausreichend Nennspannung ankommt, um einen Verbraucher zu betreiben.  Nebenstehende Formeln dienen der Berechnung des Spannungsfalls.</p>
      <p>Sowohl bei der Berechnung als auch bei der Umsetzung ist zu beachten, dass eine Erhöhung des Kabelquerschnitts bzw. die Erhöhung der Leiteranzahl Auswirkungen auf den Kurzschlussstrom hat. Durch „größere“ und mehrere Kabel, kann mehr Strom fließen, weshalb Schutzeinrichtungen, wie Sicherungen, entsprechend anders dimensioniert werden müssen. Darüber hinaus muss der Spannungsfall in Grenzen gehalten werden, um die Betriebsspannung zu garantieren, Spannungsschwankungen zu vermeiden und hohe Verluste zu umgehen. Unter Bedingungen von Gleichstrom, Wechselstrom und Drehstrom ergeben sich drei Formeln für den Spannungsabfall:</p>
      <div class="links">
        <div class="link__entry">
          <router-link to="/spannungsabfall/gleichstrom">Gleichstrom</router-link>
        </div>  
        <div class="link__entry">
          <router-link to="/spannungsabfall/wechselstrom">Wechselstrom</router-link>
        </div>
        <div class="link__entry">
          <router-link to="/spannungsabfall/drehstrom">Drehstrom</router-link>
        </div>
      </div>
    </div>
  </div>
</template>