import { createStore } from 'vuex'


export default createStore({
	state: {
		navigationOpen: false
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	},
})
