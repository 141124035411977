<template>
  <div class="calculation">
    <div class="main" v-bind:class="activeClass">
      <h1>Ohmsches Gesetz</h1>
      <h2><span class="value_u">U</span> = <span class="value_r">R</span> * <span class="value_i">I</span></h2>
      <form @submit.prevent="calculate()" class="calculation">
        <div @click="toggleActiveRow('value_u')" class="calculation__row value_u">
          <span class="error" v-if="value_u_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_u_error}" for="u">U</label>
          <input :class="{'error' : value_u_error}" v-model="value_u" type="text" name="u" placeholder="Spannung in Volt">
        </div>
         <div @click="toggleActiveRow('value_r')" class="calculation__row value_r">
          <span class="error" v-if="value_r_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_r_error}" for="r">R</label>
          <input :class="{'error' : value_r_error}" v-model="value_r"  type="text" name="r" placeholder="Widerstand in Ohm">
        </div>
        <div @click="toggleActiveRow('value_i')" class="calculation__row value_i">
          <span class="error" v-if="value_i_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_i_error}" for="i">I</label>
          <input :class="{'error' : value_i_error}" v-model="value_i"  type="text" name="i" placeholder="Strom in Ampere">
        </div>
        <div class="calculation__row">
          <div class="submission__row">
            <button type="submit">Jetzt berechnen</button>
            <table class="forms" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <td>Formel-Elemente</td>
                </tr>
              </thead>
              <tbody>
                <tr class="value_u">
                  <td><b>U:</b> Spannung (V)</td>
                </tr>
                <tr class="value_r">
                  <td><b>R:</b> Widerstand (Ω)</td>
                </tr>
                <tr class="value_i">
                  <td><b>I:</b> Strom (A)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeClass: '',
      value_u: null,
      value_u_error: false,
      value_r: null,
      value_r_error: false,
      value_i: null,
      value_i_error: false,
    }
  },
 methods: {
    toggleActiveRow(data, result = false) {
      if(result){
        this.activeClass = data + ' result';
      }else{
        this.activeClass = data;
      }

    },
    calculate(){
      this.value_u_error = false;
      this.value_r_error = false;
      this.value_i_error = false;

      if(this.value_u == 0){
        this.value_u_error = true;
        return;
      }

      if(this.value_i == 0){
        this.value_i_error = true;
        return;
      }

      if(this.value_r == 0){
        this.value_r_error = true;
        return;
      }

      if(!this.value_u && !this.value_r && !this.value_i){
          this.value_u_error = true;
          this.value_r_error = true;
          this.value_i_error = true;

      //Berechnung von U
      }else if(this.value_r && this.value_i){
        this.value_u = Math.round(this.value_r * this.value_i * 100) / 100;
        this.toggleActiveRow('value_u', true);

      //Berechnung von R
      }else if(this.value_u && this.value_i){
        this.value_r = Math.round(this.value_u / this.value_i * 100) / 100;
        this.toggleActiveRow('value_r', true);

      //Berechnung von I    
      }else if(this.value_u && this.value_r) {
        this.value_i = Math.round(this.value_u / this.value_r * 100) / 100;
        this.toggleActiveRow('value_i', true);

      }else{
        if(!this.value_u && !this.value_r) {
          this.value_u_error = true;
          this.value_r_error = true;
        }else if(!this.value_u && !this.value_i){
          this.value_u_error = true;
          this.value_i_error = true;
        }else if(!this.value_r && !this.value_i){
          this.value_r_error = true;
          this.value_i_error = true;
        }
      }
    }
  },
  watch: {
    value_r() {
      if(this.value_r == "") {
        this.value_r = null;
      }else if(this.value_r != null && typeof this.value_r == 'string'){
        this.value_r = this.value_r.replace(',', '.');
      }
    },
    value_u() {
      if(this.value_u == "") {
        this.value_u = null;
      }else if(this.value_u != null && typeof this.value_u == 'string'){
        this.value_u = this.value_u.replace(',', '.');
      }
    },
    value_i() {
      if(this.value_i == "") {
        this.value_i = null;
      }else if(this.value_i != null && typeof this.value_i == 'string'){
        this.value_i = this.value_i.replace(',', '.');
      }
    },
  }
}

</script>