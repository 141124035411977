<template>
  <div class="overview">
    <div class="main">
      <h1>Leistungsberechnung</h1>
      <p>Die elektrische Leistung lässt sich je nach Strom-Art unterschiedlich messen. Am einfachsten lässt sich die elektrische Leistungsberechnung bei Gleichstrom, der bspw. in Batterien oder in der Solartechnologie zum Einsatz kommt, oder Einphasenstrom (bzw. Wechselstrom) aus der heimischen Steckdose berechnen. Unter Bedingungen von Gleichstrom, Wechselstrom und Drehstrom ergeben sich drei Formeln für die elektrische Leistung:</p>
      <div class="links">
        <div class="link__entry">
          <router-link to="/leistungsberechnung/gleichstrom">Gleichstrom</router-link>
        </div>  
        <div class="link__entry">
          <router-link to="/leistungsberechnung/wechselstrom">Wechselstrom</router-link>
        </div>
        <div class="link__entry">
          <router-link to="/leistungsberechnung/drehstrom">Drehstrom</router-link>
        </div>
      </div>
      <p>Will man die elektrische Leistung definieren, muss der Begriff, der umgangssprachlich schlicht als "elektrischer Strom" bekannt ist, in zwei Teile dividiert werden:</p>
      <p>Unter Leistung wird eine physikalische Größe verstanden, die anzeigt, wie viel Energie in einer bestimmten Zeitspanne umgesetzt wird. Wenn dann die Leistung ihre Energie aus elektrischer Energie bezieht (Energie mittels Elektrizität übertragen oder in elektrischen Feldern gespeichert), handelt es sich um elektrische Leistung. Das Formelzeichen ist mit einem großen P gekennzeichnet; vom englischen Wort power abgeleitet. Gemessen wird in der Grundeinheit Watt (W), seltener auch in Voltampere (VA).</p>
      <p>Die simpelste elektrische Leistungsberechnung ergibt sich aus dem Produkt der elektrischen Spannung und der elektrischen Stromstärke: P=U×I Ist der Ohm’sche Widerstand bekannt, ergibt sich die elektrische Leistung aus dem Produkt des Quadranten der Stromstärke und des Widerstandes. Dann ist die Formel der Leistungsberechnung: P=I²×R oder P=U²/R</p>
    </div>
  </div>
</template>