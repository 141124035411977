import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Impressum from '../views/Impressum.vue'
import OhmschesGesetz from '../views/OhmschesGesetz.vue'

import Leistungsberechnung from '../views/Leistungsberechnung.vue'
import LeistungsberechnungDrehstrom from '../views/LeistungsberechnungDrehstrom.vue'
import LeistungsberechnungGleichstrom from '../views/LeistungsberechnungGleichstrom.vue'
import LeistungsberechnungWechselstrom from '../views/LeistungsberechnungWechselstrom.vue'


import Spannungsabfall from '../views/Spannungsabfall.vue'
import SpannungsabfallDrehstrom from '../views/SpannungsabfallDrehstrom.vue'
import SpannungsabfallGleichstrom from '../views/SpannungsabfallGleichstrom.vue'
import SpannungsabfallWechselstrom from '../views/SpannungsabfallWechselstrom.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/ohmsches-gesetz',
    name: 'OhmschesGesetz',
    component: OhmschesGesetz
  },
  {
    path: '/leistungsberechnung',
    name: 'Leistungsberechnung',
    component: Leistungsberechnung
  },
  {
    path: '/leistungsberechnung/drehstrom',
    name: 'LeistungsberechnungDrehstrom',
    component: LeistungsberechnungDrehstrom
  },
  {
    path: '/leistungsberechnung/gleichstrom',
    name: 'LeistungsberechnungGleichstrom',
    component: LeistungsberechnungGleichstrom
  },
  {
    path: '/leistungsberechnung/wechselstrom',
    name: 'LeistungsberechnungWechselstrom',
    component: LeistungsberechnungWechselstrom
  },
  {
    path: '/spannungsabfall',
    name: 'Spannungsabfall',
    component: Spannungsabfall
  },
  {
    path: '/spannungsabfall/drehstrom',
    name: 'SpannungsabfallDrehstrom',
    component: SpannungsabfallDrehstrom
  },
  {
    path: '/spannungsabfall/wechselstrom',
    name: 'SpannungsabfallWechselstrom',
    component: SpannungsabfallWechselstrom
  },
  {
    path: '/spannungsabfall/gleichstrom',
    name: 'SpannungsabfallGleichstrom',
    component: SpannungsabfallGleichstrom
  }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})


export default router
