<template>
  <div class="overview">
    <div class="main">
      <h1>Der Spannungsabfall Rechner</h1>
      <p>Die richtige Auswahl der Kabel ist bei Elektroinstallationen essentiell. Um am Ende des Kabels genügend Spannung für den Verbraucher zur Verfügung stellen zu können, müssen Sie den Spannungsabfall berechnen. Geben Sie die Daten in den kostenlosen Spannungsabfall-Rechner ein und erfahren Sie, welchen Spannungsverlust Sie einplanen müssen.</p>
    </div>
    <div class="link__entry">
      <router-link to="/ohmsches-gesetz">
        <img src="@/assets/Ω.png" />
        <span>Ohmsches Gesetz</span>
      </router-link>
    </div>
    <div class="link__entry">
      <router-link to="/leistungsberechnung">
        <img src="@/assets/P.png" />
        <span>Leistungsberechnung</span>
      </router-link>
      </div>
    <div class="link__entry">
      <router-link to="/spannungsabfall">
        <img src="@/assets/U.png" />
        <span>Spannungsabfall</span>
      </router-link>
      </div>
  </div>
</template>