<template>
		<div id="main">
			<div :class="{'navigation--open':navigationOpen}">
				<div class="logo__close"><router-link to="/">Spannungsabfall.com</router-link></div>
				<div class="navigation" v-bind:class="navigationHandler()">
					<div class="navigation__top">
						<div @click="toggleNavigation()" class="navigation__switch">
							<div class="line__wrapper">
								<div class="line"></div>
								<div class="line"></div>
								<div class="line"></div>
							</div>
							<div v-if="navigationOpen || windowWidth < 1350">
								<p>Spannungsabfall.com</p>
							</div>
						</div>
					</div>
					<div class="navigation__main">
						<router-link to="/ohmsches-gesetz">
							<div class="navigation__entry">
									<img v-if="!navigationOpen" src="@/assets/Ω_small.png" />
									<span v-else>Ohmsches Gesetz</span>
							</div>
						</router-link>
						<router-link  :class="{'navigation__entry': true, 'open': leistungOpen}" to="/leistungsberechnung">
								<img v-if="!navigationOpen" src="@/assets/P_small.png" />
								<span v-if="navigationOpen">Leistungsberechnung</span>
								<span :class="leistungHandler()" @click="toggleLeistung" v-if="navigationOpen"></span>
						</router-link>

						<!--Sub Navigation Leistungsberechnung-->

						<router-link v-if="leistungOpen" to="/leistungsberechnung/gleichstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Gleichstrom</div>
						</router-link>

						<router-link v-if="leistungOpen" to="/leistungsberechnung/wechselstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Wechselstrom</div>
						</router-link>
						
						<router-link v-if="leistungOpen" to="/leistungsberechnung/drehstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Drehstrom</div>
						</router-link>

						<!--END Sub Navigation-->

							<router-link :class="{'navigation__entry': true, 'open': spannungOpen}" to="/spannungsabfall">
									<img v-if="!navigationOpen" src="@/assets/U_small.png" />
									<span v-if="navigationOpen">Spannungsabfall</span>
									<span :class="spannungHandler()" @click="toggleSpannung" v-if="navigationOpen"></span>
							</router-link>

						<!--Sub Navigation Spannungsabfall-->
					
						<router-link v-if="spannungOpen" to="/spannungsabfall/gleichstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Gleichstrom</div>
						</router-link>

						<router-link v-if="spannungOpen" to="/spannungsabfall/wechselstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Wechselstrom</div>
						</router-link>

						<router-link v-if="spannungOpen" to="/spannungsabfall/drehstrom">
							<div v-if="navigationOpen" class="navigation__entry navigation__entry--sub">Drehstrom</div>
						</router-link>

						<!--END Sub Navigation-->

					</div>
				</div>
			</div>
			<div class="container">
				<main>
					<router-view/>
				</main>
			</div>
			<div class="sidebar">
				<a href="https://www.loxone.com/dede/business-partner/" target="_blank"><img src="@/assets/Partner.gif" /></a>
				<a href="https://www.loxone.com/dede/schulung/" target="_blank"><img src="@/assets/Schulung.gif" /></a>
				<a href="https://shop.loxone.com/" target="_blank"><img src="@/assets/Shop.gif" /></a>
			</div>
			<div class="footer">
				<p>Copyright © 2022 Loxone Electronics GmbH. All rights reserved.</p>
			</div>
		</div>
</template>

<script>
export default {
	data() {
		return {
			navigationOpen: false,
			leistungOpen: false,
			spannungOpen: false,
			windowWidth: 0,
		}
	},
	methods: {
		toggleNavigation(){
			this.navigationOpen = !this.navigationOpen;
		},
		toggleLeistung(){
			this.spannungOpen = false;
			this.leistungOpen = !this.leistungOpen;
		},
		toggleSpannung(){
			this.leistungOpen = false;
			this.spannungOpen = !this.spannungOpen;
		},

		leistungHandler(){
			if(this.leistungOpen){
				return 'plus sub__navigation--open';
			}else{
				return 'plus sub__navigation--closed';
			}
		},

		spannungHandler(){
			if(this.spannungOpen){
				return 'plus sub__navigation--open';
			}else{
				return 'plus sub__navigation--closed';
			}
		},
		navigationHandler(){
			if(this.navigationOpen){
				return 'navigation--open';
			}else{
				return 'navigation--closed';
			}
		},
		updatewidth(){
			this.windowWidth = window.innerWidth
		}
	},
	watch: {
		'$route'(){
			if(this.navigationOpen && this.windowWidth < 1350) {
				this.navigationOpen = false;
			}
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth
		});
		this.updatewidth();
	}
}
</script>
<style lang="scss">
	/*/////////////////////////////////////////////////
	// VARIABLES
	/////////////////////////////////////////////////*/
	$co-orange: #FF8A00;
	$co-white: #FFFFFF;
	$co-grey: #575C61;
	$co-red: #E73246;

	$ff-averta: 'Averta', Arial, Helvetica, sans-serif;
	$ff-averta-bold: 'AvertaExtrabold', Arial, Helvetica, sans-serif;
	$ff-averta-light: 'AvertaLight', Arial, Helvetica, sans-serif;

	$ff-noto: 'NotoSerif', Georgia, serif;
	$ff-noto-italic: 'NotoSerifItalic', Georgia, serif;

	$fs-title: 50px;
	$fs-subtitle: 43px;
	$fs-link: 18px;
	$fs-text: 16px;


	/*/////////////////////////////////////////////////
	// FONTS
	/////////////////////////////////////////////////*/
	@font-face {
		font-family: "AvertaExtrabold";
		src: url(./fonts/averta-extrabold-webfont.ttf) format("truetype");
	}
	@font-face {
		font-family: "Averta";
		src: url(./fonts/averta-regular-webfont.ttf) format("truetype");
	}
	@font-face {
		font-family: "AvertaLight";
		src: url(./fonts/averta-light-webfont.ttf) format("truetype");
	}
	@font-face {
		font-family: "NotoSerif";
		src: url(./fonts/NotoSerif-Regular.ttf) format("truetype");
	}

	@font-face {
		font-family: "NotoSerifItalic";
		src: url(./fonts/NotoSerif-Italic.ttf) format("truetype");
	}

	/*/////////////////////////////////////////////////
	// STRUCTURE
	/////////////////////////////////////////////////*/
	html{
		height: 100%;
		min-width: 500px;

		body{
			margin: 0;
			height: 100%;
			font-family: $ff-averta;
			font-weight: normal;
			min-width: 500px;

			#app {
				height: 100%;
				min-height: 100%;
				#main{
					height: 100%;
					min-height: 100%;
					display: grid;
					grid-template-columns: 80px 260px auto 410px;
					grid-template-rows: auto auto 54px;
					grid-template-areas:"navigation logo main sidebar"
										"navigation logo main sidebar"
										"footer footer footer footer";
					column-gap: 60px;
					row-gap: 0;
				}
			}

			.logo__close {
				grid-area: logo;
				margin: 29px 0px 0px 20px;
				text-transform: uppercase;
				font-family: $ff-averta-bold;
				font-size: 18px;
				line-height: 23px;
				position: absolute;
				padding-left: 75px;
				
				a {
					text-decoration: none;
					color: $co-grey;
				}
			}

			.container{
				grid-area: main;
				margin: 30px 0;

				main{
					.calculation {
						width: 100%;

						h1{
							font-size: 30px;
						}
					}
				}
			}

			.sidebar{
				grid-area: sidebar;
				padding-top: 30px;

				img {
					padding-bottom: 75px;
				}
			}

			.footer{
				grid-area: footer;
				background: $co-grey;

				p{
					text-align: center;
					color: white;
				}
			}
		}
	}

	h1{
		font-family: $ff-averta-bold;
		color: $co-grey;
		font-size: $fs-subtitle;
		line-height: 1.2em;
		margin-top: 0;
	}

	h2 {
		font-family: $ff-noto;
		font-style: italic;
		color: $co-grey;
		font-size: $fs-title;
		line-height: 1.2em;
		font-weight: normal;
	}

	p{
		font-size: $fs-text;
		line-height: 22px;
		color: $co-grey;
	}
	
	/*/////////////////////////////////////////////////
	// NAVIGATION
	/////////////////////////////////////////////////*/
	
	.navigation{
		grid-area: navigation;
		background-color: $co-grey;
		height: 100%;
		padding: 25px 0;
		position: fixed;
		box-sizing: border-box;

		a{
			color: #fff;
			font-size: $fs-link;
			line-height: 30px;
			text-decoration: none;
			font-family: $ff-averta-light;
		}

		&.navigation--closed{
			max-width: 80px;
			.logo__open {
				display: none;
			}

		}

		.navigation__top{
			padding: 0 25px;

			.close {
				position: absolute;
				top: 29px;
				}
				.close:before, .close:after {
				position: absolute;
				content: ' ';
				height: 22px;
				width: 3px;
				background-color: $co-orange;
				}
				.close:before {
				transform: rotate(45deg);
				}
				.close:after {
				transform: rotate(-45deg);
				}
			
		}

		.navigation__main{
			padding-top: 100px;
		}

		.navigation__main img,
		.navigation__bottom img{
			display: block;
		}

		.navigation__entry{
			padding: 15px 25px;
			border-bottom: 1px solid white;
			color: #fff;
			display: block;

			&:hover{
				background-color: $co-orange !important;
			}

			&.open{
				background-color: #C0C0C0;
			}

		.plus {
			position: absolute;
			right: 27px;
			width: 22px;
			height: 22px;

			&.sub__navigation--open{
				&:after{
					transform: rotate(45deg) !important;
				}
				&:before{
					transform: rotate(-45deg);
				}
			}
			}
			.plus:before, .plus:after {
				position: absolute;
				content: ' ';
				top: 4px;
				height: 22px;
				width: 3px;
				background-color: #FFFFFF;
				}
			.plus:before {
				transform: rotate(180deg);
				}
			.plus:after {
				transform: rotate(-90deg);
			}
		}

		.navigation__main > a:last-child .navigation__entry{
			border-bottom: none;
		}

		.router-link-active .navigation__entry,
		.router-link-active.navigation__entry{
			background-color: $co-orange;
		}

		.navigation__entry--sub {
			background-color:  #C0C0C0;

			&:hover {
				background-color: $co-orange;
			}
		}
		&.navigation--open .navigation__switch .line__wrapper{
			position: relative;

			.line{
				display: none;

				&:first-child{
					transform: rotate(45deg);
					display: block;
					position: absolute;
					top: 12px;
					left: 0px;
					margin: 0;
					width: 30px;
					height: 3px;
				}
				&:last-child{
					transform: rotate(-45deg);
					display: block;
					position: absolute;
					top: 12px;
					left: 0px;
					margin: 0;
					width: 30px;
					height: 3px;
				}
			}
		}
		.navigation__switch{
			display: flex;
			font-size: 0;
			line-height: 0;

			box-sizing: border-box;
			cursor: pointer;

			p{
				text-transform: uppercase;
				color: $co-white;
				margin: 0;
				line-height: 30px;
				padding-left: 10px;
				font-family: $ff-averta-bold;
				padding-left: 45px;
				font-size: 18px;
			}

			.line__wrapper{
				width: 30px;
				height: 30px;
				display: inline-block;
			}

			.line{
				width: 22px;
				height: 2px;
				display: inline-block;
				background-color: $co-orange;
				margin-bottom: 7px;

				&:last-child{
					margin-bottom: 0;
				}
			
			}

			.logo__open {
				p {	
					margin-left: 25px;
					margin-top: 0;
					color: #FFFFFF;
					text-transform: uppercase;
					font-family: $ff-averta-bold;
					font-size: $fs-link;
				}
			}
		}
	}

	
	/*/////////////////////////////////////////////////
	// OVERVIEW PAGES
	/////////////////////////////////////////////////*/
	.overview {
		.link__entry{
			a{
			display: inline-block;
			background-color: $co-grey;
			border-radius: 8px;
			font-family: $ff-averta-bold;
			padding: 20px 25px 10px 30px;
			width: 100%;
			color: white;
			text-decoration: none;
			margin-bottom: 5px;
			margin-top: 30px;
			width: 550px;
			box-sizing: border-box;
			font-size: $fs-text;
			line-height: 44px;
			text-align:left;
			
				&:hover{
					background-color: $co-orange;
				}

				span{
					vertical-align: top;
					line-height: 44px;
					display: inline-block;
					padding-left: 40px;
					font-size: 28px;
				}
			}

		}

		.links{
		display: grid;
		grid-gap: 30px;
		grid-template-columns: calc(33% - 20px) calc(33% - 20px) calc(33% - 20px);

		.link__entry a {
			width: 100%;
			text-align: center;
			font-family: $ff-averta;
			padding: 0;
		}
		}
	}

	/*/////////////////////////////////////////////////
	// CALCULATION PAGES
	/////////////////////////////////////////////////*/
	.calculation{
		.main{
			&.value_p{
				span.value_p{
					color: $co-orange;
				}

				.calculation__row.value_p input{
					color: $co-orange ;
					border-color: $co-orange;
				}

				.calculation__row.value_p label{
					color: $co-orange;
				}

				table.forms .value_p td{
					color: $co-white;
					background-color: $co-grey;
				}

				&.result{
					.calculation__row.value_p input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_p label{
						color: $co-white;
					}
				}
			}

			&.value_u{
				span.value_u{
					color: $co-orange;
				}

				.calculation__row.value_u input {
					color: $co-orange;
					border-color: $co-orange ;
				}
				
				/*.calculation__row.value_u select {
					color: $co-orange;
					border-color: $co-orange ;
				}*/

				.calculation__row.value_u label{
					color: $co-orange;
				}

				table.forms .value_u td{
					color: $co-white;
					background-color: $co-grey;
				}

				&.result{
					.calculation__row.value_u input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_u label{
						color: $co-white;
					}
				}
			}

			&.value_i{
				span.value_i{
					color: $co-orange;
				}

				.calculation__row.value_i input{
					color: $co-orange ;
					border-color: $co-orange ;
				}

				.calculation__row.value_i label{
					color: $co-orange;
				}

				table.forms .value_i td{
					color: $co-white;
					background-color: $co-grey;
				}

				&.result{
					.calculation__row.value_i input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_i label{
						color: $co-white;
					}
				}
			}

			&.value_cos{
				span.value_cos{
					color: $co-orange;
				}

				.calculation__row.value_cos input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_cos label{
					color: $co-orange;
				}

				table.forms .value_cos td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_cos td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_cos input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_cos label{
						color: $co-white;
					}
				}
			}

			&.value_a{
				span.value_a{
					color: $co-orange;
				}

				.calculation__row.value_a input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_a label{
					color: $co-orange;
				}

				table.forms .value_a td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_a td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_a input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_a label{
						color: $co-white;
					}
				}
			}

			&.value_l{
				span.value_l{
					color: $co-orange;
				}

				.calculation__row.value_l input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_l label{
					color: $co-orange;
				}

				table.forms .value_l td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_l td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_l input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_l label{
						color: $co-white;
					}
				}
			}

			&.value_Ua{
				span.value_Ua{
					color: $co-orange;
				}

				.calculation__row.value_Ua input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_Ua label{
					color: $co-orange;
				}

				table.forms .value_Ua td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_Ua td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_Ua input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_Ua label{
						color: $co-white;
					}
				}
			}

			/*&.value_Ul{
				span.value_Ul{
					color: $co-orange;
				}

				.calculation__row.value_Ul input {
					color: $co-orange;
					border-color: $co-orange ;
				}

				.calculation__row.value_Ul label{
					color: $co-orange;
				}

				table.forms .value_Ul td{
					color: $co-white;
					background-color: $co-grey;
				}

				&.result{
					.calculation__row.value_Ul input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_Ul label{
						color: $co-white;
					}
				}
			}*/

			&.value_γ{
				span.value_γ{
					color: $co-orange;
				}

				.calculation__row.value_γ input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_γ label{
					color: $co-orange;
				}

				table.forms .value_γ td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_γ td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_γ input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_γ label{
						color: $co-white;
					}
				}
			}

			&.value_r{
				span.value_r{
					color: $co-orange;
				}

				.calculation__row.value_r input{
					color: $co-orange;
					border-color: $co-orange;
				}

				.calculation__row.value_r label{
					color: $co-orange;
				}

				table.forms .value_r td{
					color: $co-white;
					background-color: $co-grey;
				}

				table.forms .value_r td.line{
					border-bottom-color: $co-white;
				}

				&.result{
					.calculation__row.value_r input{
						background-color: $co-orange ;
						color: $co-white;
						border-color: $co-orange;
					}
					.calculation__row.value_r label{
						color: $co-white;
					}
				}
			}

		}

		.formula {
			td {
				text-align: center;
				font-family: $ff-noto;
				font-size: $fs-title;
				color: $co-grey;
				padding: 30px 0 60px 0;
			}

			:first-child {
				padding-top: 0;
			}

			.line {
			border-bottom: 4px solid $co-grey;
			padding-bottom: 0;
			}
		}

		span.error {
			font-size: $fs-text;
			color: $co-red;
			padding: 20px;
		}

		div.calculation__row{
			position: relative;
			margin-bottom: 15px;

			label{
				position: absolute;
				font-size: $fs-text;
				line-height: 54px;
				right: 20px;
				bottom: 0;
				color: #C0C0C0;
				&.error{
					color: red !important;
				}
			}

			input{
				font-size: $fs-text;
				line-height: 50px;
				border: 2px solid #C0C0C0;
				border-radius: 8px;
				padding: 0 20px;
				width: 100%;
				color: $co-grey;
				box-sizing: border-box;
				outline: none;

				&.error{
					border-color: red !important;
					color: red !important;
				}
			}

			/*select{
				font-size: $fs-text;
				line-height: 50px;
				border: 2px solid #C0C0C0;
				border-radius: 8px;
				padding: 15px 20px;
				width: 100%;
				color: $co-grey;
				box-sizing: border-box;
				outline: none;
				}
			}*/

			.submission__row{
				display: flex;
				justify-content: space-between;

				button{
				appearance: none;
				color: $co-white;
				background-color: $co-grey;
				border-radius: 8px;
				font-size: $fs-text;
				line-height: 40px;
				height: 40px;
				padding: 0 20px;
				outline: none;
				border: none;
				cursor: pointer;

				}
				table.forms{
					border: 1px solid $co-orange;
					border-radius: 8px;
					overflow: hidden;
					width: 271px;

					tbody{
					> tr {
						> td{
						padding: 0;
						border-spacing: 0;
						border: 1px solid $co-orange;
						color: $co-grey;
						padding: 20px 20px;
						}
					}

					span{
						display: block;
					}
					}

					thead{
					> tr {
						> td{
						padding: 0;
						border-spacing: 0;
						border: 1px solid $co-orange;
						color: $co-white;
						background-color: $co-orange;
						padding: 10px 20px;

						}
					}
					}

					.line {
						border-bottom: 1px solid $co-grey;
					}

				}
			}
		}
	}

	@media screen and (max-width: 1350px) {	

		#app {
			#main{
				grid-template-columns: auto auto auto !important;
				grid-template-rows: 80px auto auto auto !important;
				grid-template-areas:"navigation navigation logo logo"
									"main main main main"
									"sidebar sidebar sidebar sidebar"
									"footer footer footer footer" !important;
			}
			
			.navigation__switch{
				width: 100%;
			}

			.logo__close {
				display: none;
			}
			.logo__open {
				display: inline-block;

				.close{
					display: none;
				}
			}

			.navigation {
				overflow: hidden;
				width: 100%;
				max-width: 100%;
				z-index: 999;

				&.navigation--closed{
					height: 80px;
					.navigation__main{
						display: none;
					}
				}
				&.navigation--open {
					bottom: 0;

					.navigation__main {
						left: 0;
						right: 0;
						//position: fixed;
						background: $co-grey;
						height: 100%;
						padding-top: 30px;
					}

					.logo__open .close{
						display: block;
					}
				}
			}

			.container {
				margin-left: 0;
				padding: 0px 35px;
				box-sizing: border-box;

			}
			.sidebar {
				width: 100%;
				padding: 0;
				box-sizing: border-box;

				img {
					display: none;

					&:first-child {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}

		.calculation {
			.formula {
				td {
					font-size: $fs-subtitle;
				}
			}
		}

	}
</style>
