<template>
  <div class="calculation">
    <div class="main" v-bind:class="activeClass">
      <h1>Leistungsberechnung | Drehstrom</h1>
      <h2><span class="value_p">P</span> = <span class="value_u">U</span> * <span class="value_i">I</span> * √3 * <span class="value_cos">cos φ</span></h2>
      <form @submit.prevent="calculate()" class="calculation">
        <div @click="toggleActiveRow('value_p')" class="calculation__row value_p">
          <span class="error" v-if="value_p_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_p_error}" for="p">P</label>
          <input :class="{'error' : value_p_error}" v-model="value_p" type="text" name="p" placeholder="Wirkleistung in Watt">
        </div>
        <div @click="toggleActiveRow('value_u')" class="calculation__row value_u">
          <span class="error" v-if="value_u_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_u_error}" for="u">U</label>
          <input :class="{'error' : value_u_error}" v-model="value_u" type="text" name="u" placeholder="Spannung in Volt">
        </div>
        <div @click="toggleActiveRow('value_i')" class="calculation__row value_i">
          <span class="error" v-if="value_i_error">Bitte Eingabewert überprüfen!</span>
          <label :class="{'error' : value_i_error}" for="i">I</label>
          <input :class="{'error' : value_i_error}" v-model="value_i" type="text" name="i" placeholder="Strom in Ampere">
        </div>
        <div @click="toggleActiveRow('value_cos')" class="calculation__row value_cos">
          <span class="error" v-if="value_cos_error">Der Wirkleistungfaktor muss zwischen 0 und 1 sein!</span>
          <label :class="{'error' : value_cos_error}" for="cos">cos φ</label>
          <input :class="{'error' : value_cos_error}" v-model="value_cos" type="text" name="cos" placeholder="Wirkleistungfaktor">
        </div>
        <div class="calculation__row">
          <div class="submission__row">
            <button type="submit">Jetzt berechnen</button>
            <table class="forms" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <td>Formel-Elemente</td>
                </tr>
              </thead>
              <tbody>
                <tr class="value_p">
                  <td><b>P:</b> Wirkleistung (W)</td>
                </tr>
                <tr class="value_u">
                  <td><b>U:</b> Spannung (V)</td>
                </tr>
                <tr class="value_i">
                  <td><b>I:</b> Strom (A)</td>
                </tr>
                <tr class="value_cos">
                  <td>
                    <table>
                      <tr>
                        <td colspan="3">
                          <b>cos φ</b>
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2">Wirkleistungfaktor = </td>
                        <td class="line">P</td>
                        <td rowspan="2">= cos φ</td>
                      </tr>
                      <tr>
                        <td>S</td>
                      </tr>
                    </table>
                    <span><b>P:</b> Wirkleistung</span>
                    <span><b>S:</b> Scheinleistung</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeClass: '',
      value_p: null,
      value_p_error: false,
      value_u: null,
      value_u_error: false,
      value_i: null,
      value_i_error: false,
      value_cos: null,
      value_cos_error: false,
    }
  },
  methods: {
    toggleActiveRow(data, result = false) {
      if(result){
        this.activeClass = data + ' result';
      }else{
        this.activeClass = data;
      }
      
    },
      calculate(){
      this.value_p_error = false;
      this.value_u_error = false;
      this.value_i_error = false;
      this.value_cos_error = false;

      if(this.value_p == 0){
        this.value_p_error = true;
        return;
      }

      if(this.value_u == 0){
        this.value_u_error = true;
        return;
      }

      if(this.value_i == 0){
        this.value_i_error = true;
        return;
      }

      if(this.value_cos == 0 || this.value_cos < 0 || this.value_cos > 1){
        this.value_cos_error = true;
        return;
        
      }
      
      if(!this.value_p && !this.value_u && !this.value_i && !this.value_cos){
          this.value_p_error = true;
          this.value_u_error = true;
          this.value_i_error = true;
          this.value_cos_error = true;
      
      //Berechnung von P = I*U*√3*cos
      }else if(this.value_i && this.value_u && this.value_cos){
        this.value_p = Math.round(this.value_i * this.value_u * Math.sqrt(3) * this.value_cos * 100) / 100;
        this.toggleActiveRow('value_p', true);

      //Berchnung von I = P/U/√3/cos
      }else if(this.value_p && this.value_u && this.value_cos) {
        this.value_i = Math.round(this.value_p / this.value_u / Math.sqrt(3) / this.value_cos * 100) / 100;
        this.toggleActiveRow('value_i', true);

      //Berechnung von U = P/I/√3/cos
      }else if(this.value_p && this.value_i && this.value_cos){
        this.value_u = Math.round(this.value_p / this.value_i / Math.sqrt(3) / this.value_cos * 100) / 100;
        this.toggleActiveRow('value_u', true);

      //Berechnung von cos = P/U/I/√3
      }else if(this.value_p && this.value_u && this.value_i){
        this.value_cos = Math.round(this.value_p / this.value_u / this.value_i / Math.sqrt(3) * 1000) / 1000;
        this.toggleActiveRow('value_cos', true);

      }else{
        if(!this.value_p && !this.value_u && !this.value_i) {
          this.value_p_error = true;
          this.value_u_error = true;
          this.value_i_error = true;
        }else if(!this.value_u && !this.value_i && !this.value_cos){
          this.value_u_error = true;
          this.value_i_error = true;
          this.value_cos_error = true;
        }else if(!this.value_p && !this.value_i && !this.value_cos){
          this.value_p_error = true;
          this.value_i_error = true;
          this.value_cos_error = true;
        }else if(!this.value_p && !this.value_u && !this.value_cos){
          this.value_p_error = true;
          this.value_u_error = true;
          this.value_cos_error = true;

        }else if(!this.value_p && !this.value_u) {
          this.value_p_error = true;
          this.value_u_error = true;
        }else if(!this.value_u && !this.value_i) {
          this.value_u_error = true;
          this.value_i_error = true;
        }else if(!this.value_i && !this.value_cos) {
          this.value_i_error = true;
          this.value_cos_error = true;
        }else if(!this.value_p && !this.value_i) {
          this.value_p_error = true;
          this.value_i_error = true;
        }else if(!this.value_p && !this.value_cos) {
          this.value_p_error = true;
          this.value_cos_error = true;
        }else if(!this.value_u && !this.value_cos) {
          this.value_u_error = true;
          this.value_cos_error = true;
        }
      }
    }
  },
  watch: {
    value_p() {
      if(this.value_p == "") {
        this.value_p = null;
        }else if(this.value_p != null && typeof this.value_p == 'string'){
        this.value_p = this.value_p.replace(',', '.');
      }
    },
    value_u() {
      if(this.value_u == "") {
        this.value_u = null;
      }else if(this.value_u != null && typeof this.value_u == 'string'){
      this.value_u = this.value_u.replace(',', '.');
      }
    },
    value_i() {
      if(this.value_i == "") {
        this.value_i = null;
      }else if(this.value_i != null && typeof this.value_i == 'string'){
        this.value_i = this.value_i.replace(',', '.');
      }
    },
    value_cos() {
      if(this.value_cos == "") {
        this.value_cos = null;
      }else if(this.value_cos != null && typeof this.value_cos == 'string'){
        this.value_cos = this.value_cos.replace(',', '.');
      }
    },
  }
}
</script>
